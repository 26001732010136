import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout2 from "../components/Layout2";
import Seo from "../components/Seo";
import ShareLinks from "../components/ShareLinks";
import Comp from "../components/alafasy/index";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CookieConsent from "react-cookie-consent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PopupDownloadLink from '../components/PopupDownloadLink'; // Import the Popup component

const AuthorReciter = ({ data }) => {
  let tempSlug = "";
  const reciter = data.authorReciterJson;
  let _matched;
  const location = useLocation();

  if (location.pathname.includes("translations")) {
    _matched = data.allAllTranslationsJson.nodes.filter(
      (item) => item.title === data.allAuthorTranslationsJson.edges[0].node.title
    );
    const _path = location.pathname.split('/').filter((item) => item !== "");
    tempSlug = `/${_path[_path.length - 1]}/`;
  } else {
    _matched = data.allAllRecitersJson.nodes.filter(
      (item) => item.title === reciter.title
    );
    tempSlug = reciter?.fields?.slug;
  }

  const matched = _matched;
  const { t, i18n } = useTranslation();
  const siteUrl = data.site.siteMetadata.siteUrl;

  useEffect(() => {
    console.log('matched', reciter?.fields?.slug);
  }, []);

  if (matched.length === 0) {
    return (
      <Layout2 pageTitle="Page Not Found">
        <div className="main">
          <div className="error-content-wrap text-center">
            {t(
              "Unfortunately, the requested page does not appear to exist or has been moved."
            )}
            <br />
            {t(
              "Please double-check the URL or navigate to the homepage to explore other content."
            )}
          </div>
        </div>
      </Layout2>
    );
  } else {
    const reciterData = matched[0];
    const isTranslation = location.pathname.includes("translations");
    const pathPrefix = isTranslation ? "/translations" : "/reciter";

    return (
      <Layout2 pageTitle={reciterData.title}>
        <Helmet>
          <link rel="alternate" hrefLang="en" href={`${siteUrl}${pathPrefix}${tempSlug}`} />
        </Helmet>

        <Seo
          description={t(reciterData.metaDescription)}
          title={t(reciterData.metaTitle)}
          image={reciterData.image}
          url={location.href}
          reciterData={reciterData}
        />
        <div className="main">
          <div className="text-center blue-bg">
            <h1 className="h3 post-title">{t(reciterData.title)}</h1>
            <div className="description">
              {/* Popup Trigger integrated into the Download button */}
              <PopupDownloadLink downloadLink={reciterData.downloadAll}>
                <Link
                  to="#"
                  className="btn btn-secondary lbtn"
                >
                  <span>{t("Download All Surahs")}</span>
                </Link>
              </PopupDownloadLink>
            </div>
          </div>
          
          <div className="container">
            <Comp
              t={t}
              songs={reciterData.reciters}
              author={reciterData.title}
            />
          </div>
          <div style={{ padding: '0 16px' }}>
            <div className="container about-author flex">
              <div>
                <GatsbyImage
                  image={getImage(reciterData.image)}
                  alt={reciterData.title}
                />
              </div>
              <div className="additional-info author-info">
                <h3 className="additional-info-title name h5">{t(reciterData.title)}</h3>
                <p className="additional-info-description">{t(reciterData.metaDescription)}</p>
              </div>
            </div>
          </div>
          <CookieConsent
            location="bottom"
            buttonText={t("Accept Cookies")}
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            {t("This website uses cookies to enhance the user experience.")}
          </CookieConsent>
        </div>
      </Layout2>
    );
  }
};

export default AuthorReciter;

export const query = graphql`
  query authorReciter($slug: String!, $language: String!) {
    authorReciterJson(fields: { slug: { eq: $slug } }) {
      ...AuthorReciterQueryFragment
    }
    allAllRecitersJson {
      nodes {
        title
        metaDescription
        downloadAll
        private
        metaTitle
        reciters {
          trackName
          trackURL
          downloadLink
          surahNo
        }
      }
    }
    allAuthorTranslationsJson(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          title
        }
      }
    }
    allAllTranslationsJson {
      nodes {
        title
        metaDescription
        downloadAll
        private
        metaTitle
        reciters {
          trackName
          trackURL
          downloadLink
          surahNo
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
